import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class ExcelService {

    exportExcel(items: unknown[], fileName: string, headers?: string[]) {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            if(headers && headers.length > 0) {
                xlsx.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
            }

            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
            });
            this.saveAsExcelFile(excelBuffer, fileName);
        });
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
            const EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const EXCEL_EXTENSION = ".xlsx";
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            saveAs(
                data,
                `${fileName}${EXCEL_EXTENSION}`
            );

    }
}
